import logo from '../assets/2.gif';
import './page.css';
import { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams,useNavigate } from 'react-router-dom'
import { createBrowserHistory } from 'history';


function App(props) {
  const navigate = useNavigate();
  let timeOut
  const handleRouter = () => {
    clearTimeout(timeOut)
    timeOut = setTimeout(() => {
      props.intoContent()
    }, 200)
  }

  return (
    <div  onClick={() => {props.intoContent()}} onWheel={() => {handleRouter()}} className="container-page content-index">
      <div className=" content content-home">
        <div className="App-logo"/>
        <div className="title">Omni-Liquidity Protocol – Swap, Lending, and Leverage in One Market</div>
        <div className="button"></div>
      </div>
    </div>
  );
}

export default App;

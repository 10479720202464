import './page.css';
import { init } from 'ityped'
import { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Home from './home'
import Page from './content'
function App() {
  const [isShowContent,setIsShowContent] = useState(false)
  useEffect(() => {

  },[])
  return (
    <div>
      {isShowContent ? <Page></Page> : <Home intoContent={() => {setIsShowContent(true)}}></Home>}
    </div>
  );
}

export default App;

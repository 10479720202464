import "./page.css";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  const [show, setShow] = useState(true);
  const gifList = [
    {
      text: "Supports any asset for all trading products",
    },
    {
      text: "Supports all USD(S)/COIN-margined contracts up to 20x leverage",
    },
    {
      text: "Low liquidity provider risk with diversified sources of fee earnings",
    },
  ];
  const cubeList = [
    {
      title:
        "Smart leverage for credit token issuance based on original asset collateral ",
    },
    {
      title: "Composability with other markets to increase market leverage ",
    },
    {
      title:
        "Higher capital efficiency for tokens through leverage and composability",
    },
  ];
  const link = [
    {
      text: "Developers",
      list: [
        {
          label: "Github",
          link: "https://github.com/CodexDao",
        },
        {
          label: "Documentation",
          link: "https://docs.qilin.fi/",
        },
        {
          label: "Audit",
          link: "https://github.com/CodexDao/qilin-v2/tree/master/audit",
        },
      ],
    },
    {
      text: "Community",
      list: [
        {
          label: "Discord",
          link: "https://discord.gg/D8SutwykDR",
        },
        {
          label: "Telegram",
          link: "https://t.me/QilinOfficial",
        },
        {
          label: "Twitter",
          link: "https://twitter.com/QilinProtocol",
        },
      ],
    },
    {
      text: "Explore",
      list: [
        {
          label: "PIX",
          link: "/#",
        },
      ],
    },
  ];
  return (
    <div className="container-page">
      <div className="header">
        <a href="/" className="header-logo"></a>
        <div className="header-link">
          {link.map((e, i) => (
            <div key={`link1${i}`} className="link-item">
              {e.text}
              <div className="link-container">
                {e.list.map((i, j) => (
                  <a
                    key={`link223${j}`}
                    target={i.label === "PIX" ? "" : "_blank"}
                    href={i.link}
                  >
                    {i.label}
                  </a>
                ))}
              </div>
            </div>
          ))}
          {/* <a target="_blank" className="link-item" href="https://pix.qilin.fi/">
            PIX
          </a> */}
          <a href="/#" className="header-button">
            Launch App
            <span>BETA</span>
          </a>
        </div>
        {/* <a href='https://rc.qilin.fi/nft/' className="nft-button">
          Supernova NFT
        </a> */}
      </div>
      <div className="bg"></div>
      <div className="content">
        <div className="banner">
          <div className="banner-circle">
            <div className="banner-circle-img "></div>
          </div>
          <div className="banner-img"></div>
          <div className="banner-title">
            Omni-Liquidity Protocol – Swap, Lending, and Leverage in One Market
          </div>
          <div className="banner-link">
            <a
              target="_blank"
              href="https://github.com/CodexDao/QiLin"
              rel="noreferrer"
            >
              <img
                className="link"
                src={require("../assets/github@2x.png")}
                alt=""
              />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/QilinProtocol"
              rel="noreferrer"
            >
              <img
                className="link"
                src={require("../assets/Twitter@2x.png")}
                alt=""
              />
            </a>
            <a target="_blank" href="https://discord.gg/D8SutwykDR">
              <img
                className="link"
                src={require("../assets/Discord@2x.png")}
                alt=""
              />
            </a>
            <a target="_blank" href="https://t.me/QilinOfficial">
              <img
                className="link"
                src={require("../assets/Telegram@2x.png")}
                alt=""
              />
            </a>
            <a target="_blank" href="https://medium.com/@qilinprotocol">
              <img
                className="link"
                src={require("../assets/M@2x.png")}
                alt=""
              />
            </a>
          </div>
          {show && (
            <div
              onClick={() => {
                window.open("https://immunefi.com/bounty/qilin/");
              }}
              className="card"
            >
              <div className="card-logo" alt="">
                <div className="card-img"></div>
              </div>
              <div className="card-title">Bug Bounty</div>
              <div className="card-text">
                Find bugs and win bounties on Immunefi.
              </div>
              <div
                onClick={() => {
                  setShow(false);
                }}
                className="card-close"
              ></div>
            </div>
          )}
        </div>
        <div className="common-title"></div>
        <div className="gif-box">
          {gifList.map((e, i) => (
            <div key={i} className="gif-item">
              <div className={`gif-icon gif-icon-${i}`} />
              <div className="gif-text">{e.text}</div>
            </div>
          ))}
        </div>
        <div
          data-aos="zoom-in-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <div className="common-title common-title-1">
            Consolidated debt market that provides a unified liquidity structure
            for lending, spot and derivatives markets
          </div>
          <div className="number-box">
            <div
              className="number-logo"
              data-aos="zoom-in-up"
              data-aos-offset="200"
              data-aos-delay="500"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false"
            ></div>
            <div className="number-item">
              <div className="number-label">01</div>
              <div className="number-text">
                Lower entry barrier for all assets to access financial products{" "}
                <br /> in a consolidated market{" "}
              </div>
            </div>
            <div className="number-item">
              <div className="number-label">02</div>
              <div className="number-text">
                Sufficient liquidity guaranteed for swap, lending and
                derivatives markets
              </div>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-down-right"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <div className="common-title common-title-2">
            A tokenization standard for positions that enables cross-protocol
            applications
          </div>
          <div className="square-table">
            <div className="square-box">
              <div
                data-aos="fade-down-right"
                data-aos-offset="200"
                data-aos-delay="500"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="false"
                className="square-icon"
              ></div>
              <div className="square-box-before"></div>
              <div className="square-item">
                Enables composability with other markets for new products
              </div>
              <div className="square-item square-item-2">
                Increases asset usability in other markets and protocols
              </div>
            </div>
          </div>
        </div>
        <div
          data-aos="slide-up"
          data-aos-offset="200"
          data-aos-delay="500"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <div className="common-title common-title-3">
            Synthetic liquidity minting that expands leverage trading
          </div>
          <div className="cube-box">
            {cubeList.map((e) => (
              <div key={e.title} className="cube-item">
                <div className="cube-label">{e.title}</div>
              </div>
            ))}
          </div>
        </div>

        <div
          data-aos="fade-down-right"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <div className="common-title common-title-3">Explore</div>
          <div className="explore-container">
            <a
              className="explore-card"
              target="__blank"
              href="https://pix.qilin.fi/"
            >
              <div className="explore-icon">
                <svg
                  version="1.1"
                  id="图层_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 146 211"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      class="st0"
                      fill="#fff"
                      d="M17.3,106.91l-5.5,5.5c0,0-21.97,21.09-4.12,47.63c1.87,2.77,4.06,5.31,6.42,7.67l32.7,32.7c0,0,24,24,52,1
		l41-42c0,0,17-21-4-48l-112-111l-17,17l112,113c0,0,2.3,1.38,2.84,5.71c0.54,4.28-1.09,8.54-4.14,11.59L80.8,184.41c0,0-6,6-15,0
		l-36.64-36.64c-5.34-5.34-5.2-14.09,0.41-19.15c0.08-0.07,0.16-0.14,0.24-0.21l4.5-4.5L17.3,106.91z"
                    />
                    <path
                      class="st0"
                      fill="#fff"
                      d="M126.05,109.38l10-12c0,0,24-22-1-51l-35-35c0,0-24-24-52-1L10.36,49c-4.64,4.76-7.95,10.72-9.18,17.26
		c-1.62,8.62-0.62,20.62,9.88,34.12l112,111l17-17L29.7,83.04l-2.18-2.1c-0.75-0.73-2.06-2.44-2.36-5.75
		c-0.37-4.11,1.24-8.16,4.16-11.07l36.74-36.74c0,0,6-6,15,0l36.64,36.64c5.34,5.34,5.2,14.09-0.41,19.15
		c-0.08,0.07-0.16,0.14-0.24,0.21l-8,9L126.05,109.38z"
                    />
                  </g>
                </svg>
              </div>
              <div className="explore-card-title">
                <span>PIX</span>
                <svg
                  version="1.1"
                  id="图层_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 432 432"
                  fill="#fff"
                >
                  <path
                    class="st0"
                    d="M69.55,117.29V274.3H1.28V1.23h273.07V69.5H117.34l314.02,314.03l-47.78,47.79L69.55,117.29z M69.55,117.29"
                  />
                </svg>
                <div className="status">NEW</div>
              </div>
              <div className="explore-card-decription">
                <p>
                  1. Pixiu is a derivatives trading protocol that operates
                  without the need for liquidity providers. .
                </p>
                <p>
                  2. It offers both long and short positions as counterparts,
                  allowing traders to meet their trading needs even in the
                  absence of liquidity.
                </p>
              </div>
            </a>
          </div>
        </div>

        <div className="header-link bottom">
          {link.map((e, i) => (
            <div key={`uni4${i}`} className="link-item">
              {e.text}
              <div className="link-container">
                {e.list.map((i, j) => (
                  <a key={`link${j}`} target="_blank" href={i.link}>
                    {i.label}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;

import React from 'react';
import Index from './page/index'
import {
  BrowserRouter as Router,
  Routes,
  Switch,
  Redirect,
  Navigate,
  Route,
  Link
} from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact={true} path='/' element={<Index/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
